<template>
    <div class="myCount">
        <div class="search-box" v-if="btnP.depSearch || btnP.userSearch">
            <div class="search-one">
                <p class="left">
                    <Department
                        ref="department"
                        :departmentWidth="170"
                        :adminWidth="170"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                </p>
            </div>
        </div>
        <div class="table-title">
            <p>销售费用信息管理</p>
            <span>
                <button @click="delData" v-if="btnP.delete" class="cancel">
                    批量删除
                </button>
                <button @click="addData" v-if="btnP.add" class="cancel">
                    <i class="el-icon-plus" style="font-size: 12px"></i>
                    新增
                </button>
            </span>
        </div>
        <Table ref="table" @onDetails="onDetails"></Table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 添加往来 -->
        <el-dialog
            :visible.sync="dialogDetails"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div>
                <Add ref="add" @close="handleClose"></Add>
            </div>
        </el-dialog>
        <!-- 删除联系人 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    批量删除
                </div>
            </template>
            <div class="Delcontent">
                <p>即将删除选择的销售费用信息数据，是否确认?</p>
            </div>
            <div class="but-bottom">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="handleClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmitDel"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../../components/Department';
import {
    businessInfoList,
    businessInfoGet,
    businessInfoDelete,
} from '@/api/cost/declare.js';
import Table from '../table/table.vue';
import Add from '../add/add.vue';
export default {
    data() {
        return {
            searchVal: {
                departmentId: '',
                adminId: '',
            },
            btnP: {},
            pagesize: 20,
            total: 0,
            currentPage: 1,
            dialogDetails: false,
            dialogDelete: false,
            dialogTitle: '',
        };
    },
    components: {
        Department,
        Table,
        Add,
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                setTimeout(() => {
                    this.$refs.department.getUserList(
                        sessionStorage.getItem('departmentId')
                    );
                }, 0);
            }
            this.getData();
        },
        getData() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.searchVal.departmentId) {
                data.param.departmentId = this.searchVal.departmentId;
            }

            if (this.btnP.userSearch) {
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            businessInfoList(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.table.getData(res.data.list);
                    this.total = res.data.total;
                }
            });
        },
        onDetails(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            businessInfoGet(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.dialogDetails = true;
                    setTimeout(() => {
                        if (this.btnP.update) {
                            this.dialogTitle = '编辑销售费用信息';
                            this.$refs.add.getEditData(res.data);
                        } else {
                            this.dialogTitle = '销售费用信息详情';
                            this.$refs.add.getEditData(res.data, 1);
                        }
                    }, 0);
                }
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        delData() {
            let choiceArr = this.$refs.table.choiceArr;
            if (choiceArr.length == 0) {
                return this.$message.error('请选择要删除的数据');
            } else {
                this.dialogDelete = true;
            }
        },
        onSubmitDel() {
            let choiceArr = this.$refs.table.choiceArr;
            let data = {
                param: choiceArr.map((item) => {
                    return {
                        id: item.id,
                    };
                }),
            };
            businessInfoDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.handleClose();
                }
            });
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.dempData = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        addData() {
            this.dialogDetails = true;
            this.dialogTitle = '新增销售费用信息';
            setTimeout(() => {
                this.$refs.add.getData();
            }, 0);
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getData();
        },
        handleClose() {
            this.dialogDetails = false;
            this.dialogDelete = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
.myCount {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                align-items: center;
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
            .tab_box {
                display: flex;
                width: 100px;
                height: 32px;
                background: #edf1f3;
                box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500 !important;
                align-items: center;
                .iconfont {
                    font-size: 20px;
                    color: #8292b9;
                    box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                    border-radius: 50;
                    margin-left: 6px;
                    margin-right: 8px;
                }
            }
        }
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-two {
    padding-bottom: 12px;
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    button {
        font-size: 12px;
        padding: 0 7px 0 12px;
        height: 24px;
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #2370eb;
        cursor: pointer;
        color: #2370eb;
        margin-left: 16px;
    }
    .status_btn {
        span {
            display: inline-block;
            border: 1px solid #f0f0f0;
            border-radius: 13px;
            padding: 4px 12px;
            font-size: 13px;
            color: #666666;
            margin-right: 12px;
            cursor: pointer;
            position: relative;
        }
        .isStatusColor {
            background: #e9f2ff;
            color: #2370eb;
            border-color: #e9f2ff;
        }
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.Delcontent {
    padding: 100px 80px;
    font-size: 16px;
}
.but-bottom {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
