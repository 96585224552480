<template>
    <div class="add_declare">
        <div class="add_form">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="75px"
            >
                <el-form-item label="客户" required>
                    <!-- filterable  :filter-method="conListFilter" -->
                    <el-select
                        v-model="form.customerId"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择或输入"
                        clearable
                        :disabled="Boolean(isDetails)"
                        filterable
                        :filter-method="getCustomerList"
                        @change="getCustomerContact"
                    >
                        <el-option
                            v-for="item in customerList"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名" required>
                    <el-select
                        allow-create
                        filterable
                        remote
                        v-model="value1"
                        :disabled="Boolean(isDetails)"
                        :remote-method="remoteMethod1"
                        size="small"
                        style="width: 240px"
                        placeholder="请选择搜索或直接输入"
                        value-key="id"
                        :filter-method="dataFilter1"
                        @change="getCompanyData1()"
                        ref="searchSelect1"
                    >
                        <el-option
                            v-for="item in contactList"
                            :key="item.id"
                            :label="item.contactName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-select
                        allow-create
                        filterable
                        :disabled="Boolean(isDetails)"
                        remote
                        v-model="value2"
                        size="small"
                        style="width: 240px"
                        :remote-method="remoteMethod2"
                        placeholder="请选择搜索或直接输入"
                        value-key="contactContent"
                        :filter-method="dataFilter2"
                        ref="searchSelec2"
                        @change="getCompanyData2()"
                    >
                        <el-option
                            v-for="item in customerContactWayDOList"
                            :key="item.contactContent"
                            :label="item.contactContent"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="邮箱">
                    <el-input
                        v-model="form.email"
                        size="small"
                        :disabled="Boolean(isDetails)"
                        style="width: 240px"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
                <el-form-item label="支付宝">
                    <el-input
                        v-model="form.alipay"
                        size="small"
                        :disabled="Boolean(isDetails)"
                        style="width: 240px"
                        placeholder="支付宝账号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="银行信息">
                    <el-input
                        v-model="form.bankNo"
                        :disabled="Boolean(isDetails)"
                        size="small"
                        style="width: 240px"
                        placeholder="银行卡号"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="form.bankName"
                        size="small"
                        style="width: 240px"
                        placeholder="开户行"
                        :disabled="Boolean(isDetails)"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input
                        v-model="form.address"
                        size="small"
                        :disabled="Boolean(isDetails)"
                        style="width: 240px"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>

        <div slot="footer" class="bottom-but">
            <el-button
                @click="onClose"
                round
                style="padding: 7px 20px; margin-right: 15px"
                size="mini"
                >取 消</el-button
            >
            <el-button
                v-if="!Boolean(isDetails)"
                type="primary"
                style="
                    background: #2370eb;
                    margin-right: 15px;
                    margin-left: 0;
                    padding: 7px 20px;
                "
                @click="onSubmit"
                round
                size="mini"
                >确 定</el-button
            >
        </div>
    </div>
</template>

<script>
import { customerList } from '@/api/customer/customer.js';
import { contactList } from '@/api/user/ae/customer';
import { businessInfoAdd, businessInfoUpdate } from '@/api/cost/declare.js';
import { contactAdd } from '@/api/user/ae/customer.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                customerId: '',
                email: '',
                alipay: '',
                bankName: '',
                bankNo: '',
                address: '',
            },
            customerList: [],
            singleCustomer: {},
            value1: '',
            value2: '',
            radio1: '1',
            radio2: '1',
            value: {},
            contactList: [],
            customerContactWayDOList: [],
            isDetails: 0,
            isEdit: false,
        };
    },
    components: {},
    created() {},
    methods: {
        getData() {
            this.form = {};
            this.customerList = [];
            this.singleCustomer = {};
            this.value1 = '';
            this.value2 = '';
            this.radio1 = '1';
            this.radio2 = '1';
            this.value = {};
            this.contactList = [];
            this.customerContactWayDOList = [];
            this.isEdit = false;
            this.getCustomerList();
        },
        getEditData(row, isDetails = 0) {
            // console.log(data);
            this.isEdit = true;
            this.isDetails = isDetails;
            // this.getCustomerList(data.companyName);
            this.form.customerId = row.customerId;
            this.form.email = row.email;
            this.form.alipay = row.alipay;
            this.form.bankName = row.bankName;
            this.form.bankNo = row.bankNo;
            this.form.address = row.address;
            this.form.id = row.id;
            let data = {
                param: {
                    adminId: row.adminId,
                    status: 1,
                    name: row.companyName,
                },
                pageNum: 1,
                pageSize: 20,
            };
            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerList = res.data.list;
                    this.singleCustomer = this.customerList.find((item) => {
                        return item.id == row.customerId;
                    });
                    let data2 = {
                        param: {
                            customerId: row.customerId,
                            adminId: row.adminId,
                        },
                        pageSize: 0,
                        pageNum: 0,
                    };
                    contactList(data2).then((result) => {
                        if (result.code == 200) {
                            this.contactList = result.data.list;
                            result.data.list.forEach((item) => {
                                if (item.contactName == row.nickName) {
                                    this.value1 = item;
                                    this.customerContactWayDOList =
                                        item.customerContactWayDOList;
                                    item.customerContactWayDOList.forEach(
                                        (itm) => {
                                            if (
                                                itm.contactWay == 1 &&
                                                itm.contactContent == row.phone
                                            ) {
                                                this.value2 = itm;
                                            }
                                        }
                                    );
                                }
                            });
                        }
                    });
                }
            });
        },
        // 客户列表
        getCustomerList(name) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    status: 1,
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            customerList(data).then((res) => {
                if (res.code == 200) {
                    this.customerList = res.data.list;
                }
            });
        },
        // 客户联系人列表
        getCustomerContact(val) {
            this.singleCustomer = this.customerList.find((item) => {
                return item.id == val;
            });
            let data = {
                param: {
                    customerId: val,
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageSize: 0,
                pageNum: 0,
            };
            contactList(data).then((res) => {
                if (res.code == 200) {
                    this.contactList = res.data.list;
                }
            });
        },
        remoteMethod1(query) {
            this.value1 = query;
            this.radio1 = '2';
            this.value2 = '';
        },
        dataFilter1(val) {
            this.value1 = val;
        },
        getCompanyData1(val) {
            this.customerContactWayDOList =
                this.value1.customerContactWayDOList.filter((item) => {
                    if (item.contactWay == 1) {
                        return true;
                    } else {
                        return false;
                    }
                });

            this.radio1 = '1';
        },
        dataFilter2(val) {
            this.value2 = val;
        },
        remoteMethod2(query) {
            this.value2 = query;
            this.radio2 = '2';
        },
        getCompanyData2() {
            this.radio2 = '1';
        },
        onSubmit() {
            if (!this.singleCustomer.id) {
                return this.$message.error('请选择客户');
            }
            if (this.radio1 == '1') {
                if (!this.value1.contactName) {
                    return this.$message.error('请选择或输入联系人');
                }
            } else {
                if (!this.value1) {
                    return this.$message.error('请选择或输入联系人');
                }
            }
            if (this.radio2 == '1') {
                if (!this.value2.contactContent) {
                    return this.$message.error('请选择或输入手机号');
                }
            } else {
                if (!this.value2) {
                    return this.$message.error('请选择或输入手机号');
                }
            }

            if (this.radio1 == 2 || this.radio2 == 2) {
                this.contactAdd();
            } else {
                if (this.isEdit) {
                    this.updateBusinessInfo();
                } else {
                    this.saveBusinessInfo();
                }
            }
        },

        // 添加联系人
        contactAdd() {
            let data = {
                param: {
                    customerContactDO: {},
                },
            };

            data.param.customerContactDO = {
                companyName: this.singleCustomer.companyName,
                contactName: this.value1.contactName || this.value1,
                customerContactWayDOList: [
                    {
                        contactContent:
                            this.value2.contactContent || this.value2,
                        contactWay: 1,
                    },
                ],
            };
            data.param.customerContactDO.customerId = this.singleCustomer.id;

            data.param.customerContactDO.followAdminId =
                sessionStorage.getItem('adminId');

            contactAdd(data).then((res) => {
                if (res.code == 200) {
                    if (this.isEdit) {
                        this.updateBusinessInfo();
                    } else {
                        this.saveBusinessInfo();
                    }
                }
            });
        },
        saveBusinessInfo() {
            let data = {
                param: {
                    customerId: this.singleCustomer.id,
                    nickName: this.value1.contactName || this.value1,
                    phone: this.value2.contactContent || this.value2,
                    ...this.form,
                },
            };
            businessInfoAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        updateBusinessInfo() {
            let data = {
                param: {
                    customerId: this.singleCustomer.id,
                    nickName: this.value1.contactName || this.value1,
                    phone: this.value2.contactContent || this.value2,
                    ...this.form,
                },
            };
            businessInfoUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.form = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add_declare {
    height: 100%;
    width: 100%;
    padding-top: 15px;
    .add_form {
        height: calc(100% - 99px);
        overflow-y: auto;
        .el-form {
            width: 60%;
            margin: 0 auto;
            /deep/ .el-form-item__label {
                font-weight: 600 !important;
            }
        }
        .info {
            font-weight: 600;
            span {
                flex: 1;
            }
        }
        .detailed {
            height: 48px;
            line-height: 48px;
            border-top: 1px dashed #eeeeee;
            border-bottom: 1px dashed #eeeeee;
            padding: 0 25px;
            font-size: 14px;
            color: #666666;
            font-weight: 600;
            span {
                float: right;
                cursor: pointer;
                padding: 3px 11px;
                color: #2370eb;
                border: 1px solid #2370eb;
                border-radius: 2px;
                line-height: 1.2;
                margin-top: 13px;
            }
        }

        .toast_title {
            display: block;
            width: 56px;
            height: 28px;
            position: absolute;
            top: 0px;
            left: -145px;
            line-height: 28px;
            background: rgba(35, 112, 235, 0.07);
            border-radius: 1px;
            border: 1px solid #2370eb;
            font-size: 18px;
            text-align: center;
            transform: scale(0.5);
            color: #2370eb;
        }
        .delete {
            right: -60px;
            top: 3px;
            left: auto;
            width: 80px;
            border-color: transparent;
            border-radius: 1px;
            background-color: transparent;
            color: #666;

            transform: scale(0.7);
            cursor: pointer;
        }
        .remark_info {
            font-size: 12px;
            color: #999999;
            padding: 13px 35px;
            span {
                color: #333;
            }
        }
    }
    .all_total {
        background-color: #f0f4ff;
        padding: 15px 25px;
        box-sizing: border-box;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        color: #333;
        font-weight: 600;
        // span:first-child {
        //   margin-bottom: 15px;
        // }
        i {
            font-style: normal;
            display: inline-block;
            width: 50px;
        }
        em {
            float: right;
            font-style: normal;
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
    .el-upload-list__item-thumbnail {
        width: 80px;
        height: 80px;
    }
}
/deep/ .el-upload-list__item:hover {
    background-color: #fff;
}
/deep/ .el-upload--picture-card {
    background-color: transparent;
    width: auto;
    height: auto;
    border: none;
    line-height: 1;
    display: block;
    text-align: left;
}
/deep/ .el-upload-list__item.is-success {
    width: 80px !important;
    height: 80px !important;
}
// 处理input type = number的上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/deep/ input[type='number'] {
    -moz-appearance: textfield;
}
/deep/ .el-textarea__inner {
    resize: none;
}
</style>
