<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                type="selection"
                width="50"
                :selectable="handleDisable"
                align="center"
            >
            </el-table-column>
            <el-table-column label="提交时间" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.createTime
                                ? scope.row.createTime.slice(0, 16)
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="部门" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.departmentName
                                ? scope.row.departmentName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="员工" show-overflow-tooltip width="50">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName ? scope.row.adminName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="ID" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onDetails(scope.row)"
                        >
                            {{
                                scope.row.infoNo ? scope.row.infoNo : '- -'
                            }}</el-button
                        >
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="客户" show-overflow-tooltip width="239">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.companyName
                                ? scope.row.companyName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="姓名" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.nickName ? scope.row.nickName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="20"> </el-table-column> -->
            <!-- <el-table-column label="金额" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.amount ? scope.row.amount : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.status == 1 ? '正常' : '删除' }}
                    </div>
                </template>
            </el-table-column> -->
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
            btnP: {},
            pageData: {},
            choiceArr: [],
        };
    },
    components: {},
    created() {},
    methods: {
        handleSelectionChange(val) {
            this.choiceArr = [];
            this.choiceArr = val;
        },
        getData(data) {
            this.tableData = data;
        },
        onDetails(row) {
            this.$emit('onDetails', row);
        },

        onSend(row) {
            this.$emit('onSend', row);
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        handleDisable(row) {
            if (row.status == 1) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
